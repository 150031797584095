<template>
  <el-card id="massNoteAdd">
    <header>
      <h1>群发短信模板添加</h1>
    </header>
    <el-divider></el-divider>
    <main>
      <div class="contents">
        <div class="left1">
          <div class="left-title">
            <h3>短信模板编辑</h3>
            <div @click="causeSubmit" :class="fontClass(massStatus)">
              当前状态：{{ statusClass(massStatus) }}
            </div>
            <el-button
              type="primary"
              class="upbtn"
              @click="upSubmit"
              v-if="massStatus == 0"
              plain
              disabled
              >提交审核</el-button
            >
            <el-button
              type="primary"
              class="upbtn"
              @click="upSubmit"
              v-if="massStatus == 3 || findOB == null"
              >提交审核</el-button
            >
            <el-button
              type="primary"
              class="upbtn"
              @click="againSubmit"
              v-if="(massStatus == 2 || massStatus == 3) && findOB != null"
              >提交审核</el-button
            >
            <el-button
              type="primary"
              class="upbtn"
              @click="sendSubmit"
              v-if="massStatus == 1"
              >发送短信</el-button
            >
          </div>
          <br />
          <p class="pour1">格式：【大迈通】xxxxxx 退订回T</p>
          <p class="pour">注意：短信67字为一条(包含标点符号)</p>
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请编辑短信模板"
            v-model="massContent"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="right1">
          <div class="left-title">
            <h3>号码筛选</h3>
            <el-button
              type="primary"
              class="upbtn"
              @click="allNums"
              icon="el-icon-folder-opened"
              >号码导入</el-button
            >
          </div>
          <el-divider></el-divider>
          <div class="matter">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="massPhone"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                city
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </main>
    <!-- 批量导入号码弹出框 -->
    <el-dialog title="号码导入" :visible.sync="numShow" width="30%">
      <div class="content1">
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          >点击下载模板</el-button
        >
        <!-- <a
          style="
            height: 40px;
            background-color: #67c23a;
            border-radius: 5px;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
          "
          href="http://113.250.189.239:9005/xcx_sms_phone.xls"
          target="_blank"
          >点击下载模板</a
        > -->
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="numShow = false">取 消</el-button>
          <el-button @click="numsSubmit" :disabled="disabled" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 未通过原因弹出框 -->
    <el-dialog title="驳回原因" :visible.sync="causeBox" width="30%">
      <div>驳回原因：{{ massRemark }}</div>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
let token = localStorage.getItem("TOKEN");
import { smsImportPhone, smsSendTwoMassMailing } from "@/api/sms";
import {
  smsTemplateFindOne,
  smsTemplateAdd,
  smsTemplateUpdate,
} from "@/api/sms-template";
export default {
  data() {
    return {
      id: null,
      findOB: null,
      massRemark: "",
      causeBox: false,
      massStatus: null,
      cityOptions: [],
      checkAll: false,
      massPhone: [], // 已选择的值
      cities: [],
      isIndeterminate: true,
      massContent: null,
      parentId: null,
      userId: null,
      numShow: false, //批量导入弹出框
    };
  },
  async created() {
    this.roleName = localStorage.getItem("roleName");
    console.log(localStorage.getItem("TOKEN"));
    let res = await this.getList();
    console.log("massStatus", this.massStatus);
    console.log("findOB", this.findOB);
    console.log("bool", this.massStatus == 2 || this.findOB != null);
  },
  methods: {
    // 未通过原因
    causeSubmit() {
      if (this.massStatus == 2) {
        this.causeBox = true;
      }
    },
    // 状态分类
    statusClass(massStatus) {
      // console.log("状态的值", massStatus);
      if (massStatus == 0) {
        return "待审核";
      } else if (massStatus == 1) {
        return "审核通过";
      } else if (massStatus == 2) {
        return "审核未通过";
      } else if (massStatus == 3) {
        return "未提交";
      }
    },
    // 状态分类字体样式
    fontClass(massStatus) {
      if (massStatus == 0) {
        return "massStatus0";
      } else if (massStatus == 1) {
        return "massStatus1";
      } else if (massStatus == 2) {
        return "massStatus2";
      } else if (massStatus == 3) {
        return "massStatus3";
      }
    },
    // 复选
    handleCheckAllChange(val) {
      this.massPhone = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    //下载模板
    upload() {
      window.open("http://113.250.189.239:9005/xcx_sms_phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    // 号码引入
    allNums() {
      this.numShow = true;
      this.formDate = null;
    },
    // 号码引入de数据
    numsSubmit() {
      this.numShow = false;
      Message.success("数据加载中，稍等！请勿离开页面");
      let formDate = new FormData();
      formDate.append("file", this.file);
      let res = smsImportPhone(formDate);
      res.then((res) => {
        let data = res.data.data;
        this.cityOptions = data.filter((v, i) => {
          return i <= 999;
        });
        if (data.length > 1000) {
          Message.warning("一次性只能上传1000个号码哦");
        }
        this.cities = this.cityOptions;
      });
    },
    // 发送
    sendSubmit() {
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      let userId = JSON.parse(localStorage.getItem("id"));
      let params = {
        parentId,
        userId,
        massPhone: this.massPhone,
      };
      let res = smsSendTwoMassMailing(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.getList();
      });
    },
    // 查询
    getList() {
      let params = {};
      let res = smsTemplateFindOne(params);
      res.then((res) => {
        this.findOB = res.data.data;
        this.massContent = res.data.data.massContent;
        this.id = res.data.data.id;
        this.massRemark = res.data.data.massRemark;
        this.massStatus = res.data.data.massStatus;
        Message.success(res.data.message);
      });
    },
    // 提交审核
    upSubmit() {
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      let userId = JSON.parse(localStorage.getItem("id"));
      let params = {
        massContent: this.massContent,
        parentId,
        userId,
        massStatus: 0,
      };
      let res = smsTemplateAdd(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.getList();
      });
    },
    // 再次提交审核
    againSubmit() {
      console.log("dfghjghmn", this.id);
      let params = {
        massContent: this.massContent,
        massRemark: "",
        id: this.id,
        massStatus: 0,
      };
      let res = smsTemplateUpdate(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
#massNoteAdd {
  header {
    h1 {
      font-weight: normal;
    }
  }
  main {
    .massStatus0 {
      color: #e6a23c;
    }
    .massStatus3 {
      color: #909399;
    }
    .massStatus1 {
      color: #67c23a;
    }
    .massStatus2 {
      color: red;
    }
    .content1 {
      display: flex;
      flex-direction: column;
    }
    .upload-demo {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .pour {
      color: red;
      margin-bottom: 3px;
    }
    .pour1 {
      color: #67c23a;
    }
    .left-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    h3 {
      font-weight: normal;
      color: #409eff;
    }
    .contents {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .left1,
    .right1 {
      width: 49%;
      border: 3px solid #f0f0f0;
      border-radius: 5%;
      height: 100%;
      min-height: 380px;
      padding: 20px;
      box-sizing: border-box;
    }
    #noteAdd {
      height: 100%;
    }
    .container {
      min-width: 1200px;
    }
  }
  .uploadBtn {
    width: 68%;
  }
}
</style>