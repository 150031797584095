/**
 * sms
 * 短信
 */

import request from '@/utils/request'

// 
export const smsImportPhone = (data) => {
    return request({
        method: 'post',
        url: '/sms/import-phone',
        data
    })
}
// 
export const smsSendTwoMassMailing = (data) => {
    return request({
        method: 'post',
        url: '/sms/send-two/mass-mailing',
        data
    })
}
